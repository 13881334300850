<template>
  <div>
    <section
      id="account-section"
      class="section section-blue jpadding jpadding-20"
    >
      <div id="no-sub" class="jcard jcard-main jcard-nohover">
        <div class="card-title">
          <h3>No Subscription</h3>
        </div>
        <div class="jcard-content text-center">
          <img src="@/assets/vectors/abstract/payment_processed.svg" alt="" />

          <p>Unfortunately you have no active subscription at the moment.</p>
          <div class="jcard-btn-container jcard-btn-container-center">
            <router-link :to="{ name: 'Plans' }" class="jbtn"> Subscribe Now</router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import firebase from "firebase/app";
// import C from "@/constants";

export default {
  name: "No Subscription",
  data() {
    return {
      
    };
  },
  methods: {
    // startBuy(type) {
    //   this.$store.commit("startAddContrat");
    // },
  },
};
</script>

<style scoped></style>
